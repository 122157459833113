import React from 'react';
import loadable from '@loadable/component'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

const HomePage = loadable(() => import('./Pages/HomePage'))
const ArtistsPage = loadable(() => import('./Pages/ArtistsPage'))
const ContactUsPage = loadable(() => import('./Pages/ContactUsPage'))
const AboutUsPage = loadable(() => import('./Pages/AboutUsPage'))
const ServicesPage = loadable(() => import('./Pages/ServicesPage'))

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage/>}/>
          <Route path='/artists' element={<ArtistsPage/>}/>
          <Route path='/about-us' element={<AboutUsPage/>}/>
          <Route path='/contact-us' element={<ContactUsPage/>}/>
          <Route path='/services' element={<ServicesPage/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;